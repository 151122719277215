import { Component, computed, Inject, OnInit, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CodeService } from '@Services/code.service';
import { CreateIndividualClientService } from '../../../create-individual-client/create-individual-client.service';
import { EClientType } from '../../../client-extras/client.interface';
import { AppService } from '@Services/app.service';
import { UtilityService } from '@Services/utility.service';
import { EVFunctions, FormErrorComponent, ICodeDescription, ICodeTitle } from 'ets-fe-ng-sdk';
import { CreateCorporateClientService } from '../../create-corporate-client.service';
import { FunctionCaller1 } from 'ets-fe-ng-sdk';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { PhoneNumberComponent } from 'ets-fe-ng-sdk';
import { AutocompleteComponent } from 'ets-fe-ng-sdk';
import { InputBasicComponent } from 'ets-fe-ng-sdk';
import { AsyncPipe } from '@angular/common';
import { ModalHeaderComponent } from '../../../../../../../../evolutics-shared-lib/src/lib/Shared/components/modal-header/modal-header.component';
import { IClientIndividual } from '../../../create-individual-client/individual-client-extras/individual-client.interface';
import { ICorporateClient } from '../../corporate-client-extras/corporate-client.interface';
import { merge } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { Client1Service } from '@Services/client.service';
import { IStrictFormGroup } from '@Shared/models/index.model';
import { environment } from '@environments/environment';
import { AgentService } from 'projects/evolutics-client-ui/src/app/Services/agent.service';
import { UserService } from '@Services/user.service';

@Component({
    selector: 'app-create-new-client-modal',
    templateUrl: './create-new-client-modal.component.html',
    styleUrls: ['./create-new-client-modal.component.scss'],
    imports: [
    ModalHeaderComponent,
    InputBasicComponent,
    AutocompleteComponent,
    PhoneNumberComponent,
    BtnComponent,
    AsyncPipe,
    FunctionCaller1,
    FormErrorComponent
]
})
export class CreateNewClientModalComponent implements OnInit {
  minNameLength = 2;
  form: FormGroup<typeof this.formStructure.controls> = cloneDeep(this.formStructure);
  loading: boolean = false;
  sectorList: ICodeTitle[] = [];
  segmentList: ICodeTitle[] = [];
  countryList: ICodeDescription[] = [];
  readonly type: EClientType;
  eClientType = EClientType;
  readonly ninLength = 11;
  readonly defaultSource = environment.isSalesPortal
    ? environment.webUser.teamCat === 'A'
      ? environment.webUser.agentNo
      : environment.webUser.username
    : null;

  get formStructure() {
    return new FormGroup({
      personalInformation: new FormGroup({
        email: new FormControl(null, Validators.email, this.validateEmail.bind(this)),
        firstName: new FormControl<string>(
          null,
          this.type == EClientType.individual
            ? [
                Validators.required,
                Validators.minLength(this.minNameLength),
                this.uS.noNumberValidator.bind(this),
              ]
            : [],
        ),
        surname: new FormControl<string>(
          null,
          this.type == EClientType.individual
            ? [
                Validators.required,
                Validators.minLength(this.minNameLength),
                this.uS.noNumberValidator.bind(this),
              ]
            : [],
        ),
        middleName: new FormControl<string>(null, this.uS.noNumberValidator.bind(this)),
        phoneNumber: new FormControl(null, null, this.validatePhoneNo.bind(this)),
        address: new FormControl(null),
        // relationship: new FormControl(null),
        busLine: new FormControl(this.appS.getCurrentSystemMetadata?.busline),
        type: new FormControl(EClientType.individual),
        dateOfBirth: new FormControl<string>(null),
        gender: new FormControl(null),
        sector: new FormControl(null),
        segment: new FormControl(null),
        fullName: new FormControl(null, Validators.required),
        companyRedgNo: new FormControl(null),
        country: new FormControl(null),
        website: new FormControl(null),
        sourceCat: new FormControl(null),
        source: new FormControl(this.defaultSource),
      }),
      identification: new FormGroup({
        nationalInsuranceNumber: new FormControl(null, []),
      }),
      employmentInformation: new FormGroup<IStrictFormGroup<IClientIndividual['employmentInformation']>>({
        employStatus: new FormControl(null),
        employerSector: new FormControl(null),
        incomeBand: new FormControl(null),
        occupation: new FormControl(null),
        employerClientNo: new FormControl(null),
        retirementDate: new FormControl<string>(null),
        educationLevel: new FormControl<string>(null),
        _employerFullname: new FormControl(null),
        companyName: new FormControl(null),
        occupationGroup: new FormControl(null),
      }),
      nextOfKin: new UntypedFormArray([]),
      health: new FormGroup({
        bloodGroup: new FormControl(null),
        bmi: new FormControl(null),
        clientHealthPreExist: new FormArray([
          new FormGroup({
            clientNo: new FormControl<string>(null),
            condition: new FormControl<string>(null),
          }),
        ]),
        genotype: new FormControl(null),
        height: new FormControl(null),
        preExistCondition: new FormControl(null),
        weight: new FormControl(null),
      }),
    });
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { type?: EClientType; useEndorsement?: boolean; occupationGroup?: string },
    public clientS: Client1Service,
    public dialogRef: MatDialogRef<CreateNewClientModalComponent>,
    public cS: CodeService,
    public createIndividualClientS: CreateIndividualClientService,
    public appS: AppService,
    private formService: CreateCorporateClientService,
    public uS: UtilityService,
    public agentS: AgentService,
    public userS: UserService,
  ) {
    this.type = data.type;
  }

  ngOnInit(): void {
    //this.form = cloneDeep(this.formStructure);
    this.form.controls.identification.controls.nationalInsuranceNumber.setAsyncValidators(
      this.type != EClientType.corporate ? this.clientS.checkUniqueNIN().bind(this) : null,
    );
    merge(
      this.form.controls.personalInformation.controls.firstName.valueChanges,
      this.form.controls.personalInformation.controls.surname.valueChanges,
      this.form.controls.personalInformation.controls.middleName.valueChanges,
    ).subscribe((r) => {
      const { firstName, surname, middleName } = this.form.controls.personalInformation.getRawValue();
      this.form.controls.personalInformation.controls.fullName.patchValue(
        EVFunctions.strConcatenator(
          EVFunctions.strConcatenator(firstName, middleName, ' ').label,
          surname,
          ' ',
        ).label,
      );
    });
    this.setSectorList();
    this.setSegmentList();
    this.setCountryList();
  }

  /**
   * check if email no exist
   * @param control email form control
   * return promise
   */
  validateEmail(control: FormControl) {
    return new Promise((res) => {
      if (!control.value) res(null);
      else
        this.clientS
          .checkIfClientExistsBy({ email: control.value })
          .toPromise()
          .then((r) => {
            if (r) this.getClientNo();
            res(r ? { notUnique: true } : null);
          })
          .catch(() => res({ notExist: true }));
    });
  }

  /**
   * check if phone no exist
   * @param control phone no form control
   * return promise
   */
  validatePhoneNo(control: FormControl) {
    return new Promise((res) => {
      if (!control.value) res(null);
      else
        this.clientS
          .checkIfClientExistsBy({ phoneNumber: control.value })
          .toPromise()
          .then((r) => {
            if (r) this.getClientNo();
            res(r ? { notUnique: true } : null);
          })
          .catch(() => res({ notExist: true }));
    });
  }

  /**
   * get client no by phone no and email
   */
  getClientNo() {
    const { email, phoneNumber } = this.form.controls.personalInformation.getRawValue();
    if (email && phoneNumber)
      this.clientS.validateClientExistsByPhoneNoAndEmail(email, phoneNumber).subscribe(async (r) => {
        if (r) {
          await this.uS.info(`Email and Phone No attached to client ${r}`, 1);
          this.dialogRef.close(r);
        }
        console.log(r);
      });
  }

  setSectorList() {
    this.formService.getSectorList().subscribe((res) => {
      this.sectorList = res;
    });
  }
  setSegmentList() {
    this.formService.getSegmentList().subscribe((res) => {
      this.segmentList = res;
    });
  }
  setCountryList() {
    this.formService.getCountryList().subscribe((res) => {
      this.countryList = res;
    });
  }

  /**
   * submit
   */
  submitIndividual() {
    this.loading = true;
    const { dateOfBirth: dateOfBirthCtrl } = this.form.controls.personalInformation.controls;
    if (dateOfBirthCtrl.value) {
      dateOfBirthCtrl.patchValue(new Date(dateOfBirthCtrl.value).toISOString());
    }
    let payload = this.form.getRawValue();
    if (this.data.occupationGroup) payload.employmentInformation.occupationGroup = this.data.occupationGroup;
    let myJSON = JSON.stringify(payload);
    const uFrm = new FormData();
    uFrm.append('individualClient', myJSON);
    if (!this.data.useEndorsement) {
      this.createIndividualClientS.createClientNoEndorsement(uFrm).subscribe(
        (res) => {
          this.uS.info(
            `Individual Client ${res?.data.personalInformation?.clientNo} Created Successfully`,
            1,
          );
          this.dialogRef.close({
            clientNo: res?.data.personalInformation?.clientNo,
            // relationship: val.personalInformation.relationship,
          });
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.uS.info(error, 0);
          this.loading = false;
        },
      );
    } else {
      this.createIndividualClientS.submitPostData(uFrm).subscribe(
        async (res) => {
          await this.uS.handleEndorsementSubmmission({
            endorsementRes: res,
            endorsementMsgPrefix: `Individual Client ${res?.data?.personalInformation?.clientNo}`,
            msgPrefix: `Individual Client`,
            cb: (r) => {
              this.dialogRef.close({
                clientNo: res?.data?.personalInformation?.clientNo,
                // relationship: val.personalInformation.relationship,
              });
            },
            endorsementCB: (r) => {
              this.dialogRef.close();
            },
          });
        },
        (error) => {
          console.log(error);
          this.uS.info(error, 0);
          this.loading = false;
        },
      );
    }
  }

  /**
   * create corporate client
   */
  submitCorporate() {
    this.loading = true;
    this.form.controls.personalInformation.patchValue({ type: EClientType.corporate });
    const val = this.form.getRawValue();
    let payload: ICorporateClient = {
      companyInformation: val.personalInformation,
    };
    let myJSON = JSON.stringify(payload);
    const uFrm = new FormData();
    uFrm.append('corporateClient', myJSON);
    if (!this.data.useEndorsement) {
      this.createIndividualClientS.createCorporateClientNoEndorsement(uFrm).subscribe(
        (res) => {
          this.uS.info(`Corporate Client ${res?.data.companyInformation?.clientNo} Created Successfully`, 1);
          this.dialogRef.close({ clientNo: res?.data.companyInformation?.clientNo });
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.uS.info(error, 0);
          this.loading = false;
        },
      );
    } else {
      this.createIndividualClientS.createCorporateData(uFrm).subscribe(
        async (res) => {
          await this.uS.handleEndorsementSubmmission({
            endorsementRes: res,
            endorsementMsgPrefix: `Individual Client ${res?.data?.companyInformation?.clientNo}`,
            msgPrefix: `Individual Client`,
            cb: (r) => {
              this.dialogRef.close({ clientNo: res?.data?.companyInformation?.clientNo });
            },
            endorsementCB: (r) => {
              this.dialogRef.close();
            },
          });
        },
        (error) => {
          console.log(error);
          this.uS.info(error, 0);
          this.loading = false;
        },
      );
    }
  }
}

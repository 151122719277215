import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Client2Service } from 'projects/evolutics-client-ui/src/app/Services/client.service';
import { CreateCorporateClientService } from '../create-corporate-client.service';

import { ToAnyPipe } from 'ets-fe-ng-sdk';
import { ValidationMessageComponent } from 'ets-fe-ng-sdk';
import { MatMenuModule } from '@angular/material/menu';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { InputBasicComponent } from 'ets-fe-ng-sdk';

import { CreateNewClientModalComponent } from '../modal/new-client-modal/create-new-client-modal.component';
import { UtilityService } from '@Services/utility.service';

@Component({
    selector: 'app-directors-form',
    templateUrl: './directors-form.component.html',
    styleUrls: ['./directors-form.component.scss'],
    imports: [
    FormsModule,
    ReactiveFormsModule,
    InputBasicComponent,
    BtnComponent,
    MatMenuModule,
    ValidationMessageComponent,
    ToAnyPipe
]
})
export class DirectorsFormComponent implements OnInit {
  @Input() form: UntypedFormArray;
  relationList: any = [];
  constructor(
    private formService: CreateCorporateClientService,
    public fb: UntypedFormBuilder,
    public clientS: Client2Service,
    private uS: UtilityService
  ) {}

  ngOnInit(): void {
    this.onContactInfo();
    this.setRelationList();
  }
  patchClientInfo(control: AbstractControl) {
    return new Promise<any>((resolve) => {
      if (!control?.value) {
        resolve(null);
      } else {
        setTimeout(() => {
          this.clientS.getClientBasicDataLiteByNo(control?.value).subscribe(
            (r) => {
              if (r?.clientNo) {
                (<FormGroup>control.parent).patchValue({
                  dob: r.dateOfBirth,
                  email: r.email,
                  fullName: r.fullName,
                  phoneNumber: r.phone,
                });
                resolve(null);
              } else {
                resolve({ notFound: true });
              }
            },
            (e) => {
              resolve({ notFound: true });
            }
          );
        }, 1000);
      }
    });
  }
  onContactInfo(index: number = this.form.controls.length) {
    this.form.insert(index, this.createContactData());
  }
  createContactData(): FormGroup {
    const form = new FormGroup({
      dob: new FormControl(),
      email: new FormControl(),
      fullName: new FormControl(),
      phoneNumber: new FormControl(),
      relationship: new FormControl('DI'),
      relClientNo: new FormControl(null, null, this.patchClientInfo.bind(this)),
    });
    form.get('relationship').disable();
    return form;
  }
  onDeleteContact(index: any) {
    if (this.form.controls.length === 1) {
      this.form.reset();
      return;
    }
    this.form?.removeAt(index);
  }
  onSubmit() {
    console.log(this.form);
    this.formService.directorInfo(this.form.value as any);
    let ss3 = { directors: this.form.value };
  }
  /*  copy() {
    this.items.push(this.items)
 } */
  changeTab(tab: any) {
    console.log('tab');
    this.formService.tabChange(tab);
  }
  setRelationList() {
    this.formService.getRelationList().subscribe((res) => {
      this.relationList = res;
      console.log('relationList', res);
    });
  }

  /**
   * open a Modal to create a new client
   * @param control
   * @param type
   */
  openCreateClient(control: AbstractControl, type: 'I' | 'C') {
    this.uS.dialogOpener(
      CreateNewClientModalComponent,
      {
        data: {
          type,
        },
        width: '75vw',
        maxWidth: '80vw',
        height: 'auto',
        maxHeight: '80vw',
      },
      (r: any) => {
        control.patchValue(r);
      }
    );
  }
}

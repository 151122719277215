import { Component, ViewChild } from '@angular/core';
import { CreateIndividualClientService } from '../create-individual-client.service';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';
import { UtilityService } from '@Services/utility.service';
import { environment } from '@environments/environment';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '@Services/app.service';
import { IdentificationComponent } from '../identification/identification.component';
import { IDocMetadata, IStrictFormGroup, ITab } from '@Shared/models/index.model';
import { Client2Service } from 'projects/evolutics-client-ui/src/app/Services/client.service';
import { DocumentService } from 'projects/evolutics-client-ui/src/app/Services/document.service';
import { IClientIndividual } from '../individual-client-extras/individual-client.interface';
import { EClientType } from '../../client-extras/client.interface';
import { CreateAccountFormComponent } from 'projects/evolutics-client-ui/src/app/Life/life-components/create-account-modal/create-account-form/create-account-form.component';
import { PageTemplateComponent } from '@Shared/components/page-template/page-template.component';
import { merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@Shared/shared.module';
import { CreateIndividualClientModule } from '../create-individual-client.module';
import { EVFunctions } from 'ets-fe-ng-sdk';

@Component({
  selector: 'app-allforms',
  templateUrl: './create-individual-client.component.html',
  styleUrls: ['./create-individual-client.scss'],
  imports: [CommonModule, SharedModule, CreateIndividualClientModule, CreateAccountFormComponent],
})
export class CreateIndividualClientComponent extends PageTemplateComponent {
  minNameLength = 2;
  readonly individualForm = new FormGroup({
    personalInformation: new FormGroup({
      address: new FormControl(null),
      addressCountry: new FormControl(),
      addressGeolocation: new FormControl<string>(null),
      addressRegion: new FormControl(null),
      addressState: new FormControl(null),
      addressTown: new FormControl(null),
      alternativeEmail: new FormControl(
        null,
        [Validators.email, this.clientS.checkAlternateEmail.bind(this)],
        this.clientS.validateEmail.bind(this),
      ),
      altPhoneNumbers: new UntypedFormArray([]),
      // alternativePhoneNumber: new FormControl(null, [
      //   this.clientS.checkAlternatePhone.bind(this),
      // ]),
      busLine: new FormControl(this.appS.getCurrentSystemMetadata?.busline, Validators.required),
      clientNo: new FormControl(null),
      dateOfBirth: new FormControl(null),
      // dateOfBirthTime: new FormControl(null),
      type: new FormControl(EClientType.individual, Validators.required),
      email: new FormControl(null, Validators.email, this.clientS.validateEmail.bind(this)),
      firstName: new FormControl<string>(null, [
        Validators.required,
        Validators.minLength(this.minNameLength),
        this.uS.noNumberValidator.bind(this),
      ]),
      gender: new FormControl(null),
      maritalStatus: new FormControl(null),
      title: new FormControl<string>(null),
      placeOfBirth: new FormControl<string>(null),
      middleName: new FormControl<string>(null, this.uS.noNumberValidator.bind(this)),
      nationality: new FormControl(null),
      phoneNumber: new FormControl(null, [], this.clientS.checkUniquePhoneNumber.bind(this)),
      reason: new FormControl<string>(null),
      vip: new FormControl<boolean>(null),
      pep: new FormControl<boolean>(null),
      surname: new FormControl<string>(null, [
        Validators.required,
        Validators.minLength(this.minNameLength),
        this.uS.noNumberValidator.bind(this),
      ]),
    }),
    identification: new FormGroup<IStrictFormGroup<IClientIndividual['identification']>>({
      idType: new FormControl<string>(null),
      idIssueDate: new FormControl<string>(null),
      idExpiryDate: new FormControl<string>(null),
      intPassNo: new FormControl<string>(null),
      nationalInsuranceNumber: new FormControl<string>(null, [], this.clientS.checkUniqueNIN().bind(this)),
      idNumber: new FormControl<string>(null, [], this.clientS.checkIDUniqueness.bind(this)),
      taxIdentificationNumber: new FormControl<string>(null, [], this.clientS.checkUniqueTIN.bind(this)),
      bvn: new FormControl<string>(
        null,
        [this.uS.onlyNumberValidator.bind(this)],
        this.clientS.checkUniqueBVN().bind(this),
      ),
      pensionCommissionNumber: new FormControl<string>(null, [], this.clientS.checkUniquePenCom().bind(this)),
    }),
    employmentInformation: new FormGroup<IStrictFormGroup<IClientIndividual['employmentInformation']>>({
      employStatus: new FormControl(null),
      employerSector: new FormControl(null),
      incomeBand: new FormControl(null),
      occupation: new FormControl(null),
      employerClientNo: new FormControl(null),
      retirementDate: new FormControl<string>(null),
      educationLevel: new FormControl<string>(null),
      _employerFullname: new FormControl(null),
      companyName: new FormControl(null),
      occupationGroup: new FormControl(null),
      staffNo: new FormControl(null),
    }),
    nextOfKin: new UntypedFormArray([]),
    health: new FormGroup({
      bloodGroup: new FormControl(null),
      bmi: new FormControl(null),
      clientHealthPreExist: new FormArray([
        new FormGroup({
          clientNo: new FormControl<string>(null),
          condition: new FormControl<string>(null),
        }),
      ]),
      genotype: new FormControl(null),
      height: new FormControl(null),
      preExistCondition: new FormControl(null),
      weight: new FormControl(null),
    }),
  });
  coversBonus: any = FormGroup;
  loading: boolean;
  clientType = EClientType.individual;
  redirectRoute: string;
  tabs: ITab[] = [
    { label: 'Personal Information' },
    { label: 'Identification' },
    { label: 'Employment Information' },
    { label: 'Payment Information' },
    { label: 'Relationship' },
  ];
  set reason(v: string) {
    this.individualForm?.controls.personalInformation.patchValue({
      reason: v,
    });
  }
  selectedIndex = 0;
  constructor(
    public createIndividualClientS: CreateIndividualClientService,
    public fb: UntypedFormBuilder,
    public uS: UtilityService,
    public route: ActivatedRoute,
    public appS: AppService,
    public clientS: Client2Service,
    public docS: DocumentService,
  ) {
    super();
  }
  ngOnInit(): void {
    this.redirectRoute = this.route.snapshot.queryParamMap.get('r');

    this.individualForm.controls.identification.controls.idType.valueChanges.subscribe((r) => {
      this.individualForm.controls.identification.controls.idNumber.updateValueAndValidity();
    });
    this.individualForm.controls.personalInformation.controls.address.valueChanges
      .pipe(debounceTime(600))
      .subscribe((r) => {
        this.clientS.getGeolocation({ address: r }).subscribe((g) => {
          const { _latLong } = g?.data?.[0];
          this.individualForm.controls.personalInformation.controls.addressGeolocation.patchValue(_latLong);
        });
      });
    this.individualForm.controls.identification.controls.idNumber.valueChanges
      .pipe(debounceTime(900))
      .subscribe((r) => {
        if (this.identification.value.idType == 'NIN')
          this.identification.controls.nationalInsuranceNumber.patchValue(r);
        else if (this.identification.value.idType == 'IP')
          this.identification.controls.intPassNo.patchValue(r);
      });
    merge(
      this.personalF.controls.firstName.valueChanges,
      this.personalF.controls.middleName.valueChanges,
      this.personalF.controls.surname.valueChanges,
    )
      .pipe(debounceTime(1000))
      .subscribe((r) => {
        const { firstName, middleName, surname } = this.personalF.getRawValue();
        this.paymentForm?.controls.accountName.patchValue(
          EVFunctions.strConcatenator(
            EVFunctions.strConcatenator(firstName, middleName, ' ').label,
            surname,
            ' ',
          ).label,
        );
      });
    this.healthFG.controls.preExistCondition.valueChanges.subscribe((r) => {
      if (r) {
        this.healthFG.controls.clientHealthPreExist.enable();
      } else {
        this.healthFG.controls.clientHealthPreExist.controls.splice(1);
        this.healthFG.controls.clientHealthPreExist.disable();
      }
    });
    if (this.modelData) {
      // debugger;
      this.reason = this.modelData.reason;
    }
    /* if (!environment.production) {      this.individualForm.patchValue(testData);      this.individualForm.markAllAsTouched()    }*/
  }
  ngAfterViewInit(): void {
    //
  }
  get personalF() {
    return this.individualForm.controls.personalInformation;
  }
  get identification() {
    return this.individualForm.controls.identification;
  }
  get employ() {
    return this.individualForm.controls.employmentInformation;
  }
  get healthFG() {
    return this.individualForm.controls.health;
  }
  get paymentForm() {
    return this.paymentinfoRef?.form;
  }
  get nextofkinFA() {
    return this.individualForm.get('nextOfKin') as UntypedFormArray;
  }
  get isDOBRequired() {
    return this.reason;
  }

  viewPreview() {}

  formatPayload(data: IClientIndividual) {
    data.nextOfKin = data.nextOfKin?.filter((x) => x.relClientNo) || [];
    const nums: string[] = this.personalF.controls.altPhoneNumbers.value;
    for (let index = 0; index < nums.length; index++) {
      const item = nums[index];
      data.personalInformation['alternativePhoneNumber' + (index ? index + 2 : '')] = item;
    }
    delete data['altPhoneNumbers'];
    data.health.clientHealthPreExist = data.health.clientHealthPreExist?.filter((x) => x.condition) || [];
    data.personalInformation.dateOfBirth =
      data.personalInformation.dateOfBirth && data.personalInformation.dateOfBirth + 'T00:00';
    if (
      data.paymentInformation &&
      Object.keys(data.paymentInformation).filter((x) => data.paymentInformation[x])?.length == 1
    )
      data.paymentInformation.accountType = null;
    // debugger;
  }
  @ViewChild('identificationTab') identificationRef: IdentificationComponent;
  @ViewChild('paymentinfo') paymentinfoRef: CreateAccountFormComponent;
  //  @ViewChild('nextofkinGroupTab') nextofkinGroupTabRef: NextofkinComponent;

  async onSubmit() {
    this.loading = true;
    try {
      // debugger
      this.createIndividualClientS.clearTemp();
      let pensionCommissionNumber = this.identification.getRawValue().pensionCommissionNumber;
      if (pensionCommissionNumber)
        pensionCommissionNumber = this.identificationRef.penComPrefix + pensionCommissionNumber;
      const paymentInfo = this.paymentinfoRef?.form.getRawValue();
      const data: IClientIndividual = {
        personalInformation: this.personalF.getRawValue() as any,
        identification: {
          ...this.identification.getRawValue(),
          pensionCommissionNumber,
        } as any,
        employmentInformation: this.employ.getRawValue() as any,
        paymentInformation: paymentInfo?.country ? (paymentInfo as any) : null,
        //  nextOfKin: this.nextofkinGroupTabRef.form.getRawValue() as any,
        health: this.healthFG.getRawValue() as any,
      };
      this.formatPayload(data);
      const doc: IDocMetadata[] = [];
      let myJSON = JSON.stringify(data);
      const uFrm = new FormData();
      if (this.identificationRef.passportFile) {
        uFrm.append('files', this.identificationRef.passportFile, this.identificationRef.passportFile.name);
        doc.push({
          boxNo: environment?.userProfile?.users.docBox,
          category: 'CLI',
          sensitivity: '2',
          subCategory: 'PP',
          title: 'Passport',
        });
      }
      if (this.identificationRef.idcardFile) {
        uFrm.append('files', this.identificationRef.idcardFile, this.identificationRef.idcardFile.name);
        doc.push({
          boxNo: environment?.userProfile?.users.docBox,
          category: 'CLI',
          sensitivity: '12',
          subCategory: 'ID',
          title: 'Identitycard',
        });
      }
      if (this.paymentinfoRef?.accProofFile) {
        uFrm.append('files', this.paymentinfoRef.accProofFile, this.paymentinfoRef.accProofFile.name);
        doc.push({
          boxNo: '19',
          category: 'CLI',
          sensitivity: '12',
          subCategory: 'RIB',
          title: 'ProofofAccount',
        });
      }
      uFrm.append('individualClient', myJSON);
      if (doc?.length) uFrm.append('document', JSON.stringify(doc));
      this.createIndividualClientS.submitPostData(uFrm).subscribe(
        async (res) => {
          // console.log(res)
          this.uS.handleEndorsementSubmmission({
            endorsementRes: res,
            endorsementMsgPrefix: `Individual Client`,
            msgPrefix: `Individual Client ${res?.data?.personalInformation?.clientNo}`,
            // route: this.route,
            cb: (r) => {
              this.createIndividualClientS.calculateBMI(res?.data?.personalInformation?.clientNo).subscribe(
                (r) => {},
                (e) => {
                  this.uS.notify(e);
                },
              );
              if (this.isModal()) {
                this.modalOnComplete(res?.data);
              } else
                this.uS.goR('../../view-client', {
                  queryParams: {
                    clientNo: res?.data?.personalInformation?.clientNo,
                  },
                });
            },
            endorsementCB: (r) => {
              if (this.isModal()) {
                this.modalOnComplete(res?.data);
              } else
                this.individualForm.reset({
                  personalInformation: {
                    busLine: this.appS.getCurrentSystemMetadata?.busline,
                    type: EClientType.individual,
                  },
                });
              this.loading = false;
            },
          });
        },
        (error) => {
          console.log(error);
          this.uS.info(error, 0);
          this.loading = false;
        },
      );
    } catch (error) {
      console.log(error);
      this.uS.info(error, 0);
      this.loading = false;
    }
  }
}
const testData = {
  personalInformation: {
    clientNo: 'cl140',
    surname: 'Lawal',
    firstName: 'Ahmed',
    busLine: 'L',
    middleName: 'AHMED',
    dateOfBirth: '2022-02-24',
    email: 'ja@d.com',
    alternativeEmail: 'dd@d.cp',
    type: 'I',
    gender: 'male',
    phoneNumber: '31wdw3344',
    alternativePhoneNumber: '2334dw34',
    maritalStatus: 'divorced',
    nationality: 'NGA',
    address: 'addre',
    addressTown: 'town',
    addressState: 'BEN',
    addressCountry: 'NGA',
    addressGeolocation: '244',
    addressRegion: 'NGA',
  },
  identification: {
    id: null,
    clientNo: 'cl140',
    idExpiryDate: '2022-02-25',
    idNumber: 'ewfrfrfwdwrfw',
    nationalInsuranceNumber: '23456789fwefe85343',
    bvn: '8qdq7565323',
    pensionCommissionNumber: '8764534dwd6576',
    idType: 'wdwdwd',
    taxIdentificationNumber: 'qedewewdwwfrr',
  },
  employmentInformation: {
    id: 62,
    employStatus: 'E',
    companyName: 'vrerer',
    employerSector: 'AD',
    employerClientNo: 23,
    incomeBand: '344434',
    occupationGroup: 'EGR',
    occupation: 'CHE',
    employmentDate: null,
    educationLevel: null,
    clientNo: 'CL140',
    createdOn: null,
    createdBy: null,
    cadre: null,
    staffNo: null,
    jobTitle: null,
    active: null,
  },
  paymentInformation: null,
  nextOfKin: [
    {
      clientNo: 'CL140',
      relationship: 'CON',
      category: null,
      fullName: 'wded',
      email: 'aa@ff.com',
      phoneNumber: '442',
      id: 375,
      clientStatus: null,
      policyNo: null,
      codeTitle: null,
      policyCode: null,
      relClientNo: null,
      relDt: null,
      userCode: null,
      createdBy: null,
    },
    {
      clientNo: 'CL140',
      relationship: '',
      category: null,
      fullName: '',
      email: '',
      phoneNumber: '',
      id: 376,
      clientStatus: null,
      policyNo: null,
      codeTitle: null,
      policyCode: null,
      relClientNo: null,
      relDt: null,
      userCode: null,
      createdBy: null,
    },
  ],
  fileNames: null,
};

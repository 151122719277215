import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Client2Service } from 'projects/evolutics-client-ui/src/app/Services/client.service';
import { UtilityService } from '@Services/utility.service';
import { EClientType } from '../../client-extras/client.interface';
import { CreateIndividualClientService } from '../create-individual-client.service';
import { TranslatePipe } from '../../../../../../../evolutics-shared-lib/src/lib/Shared/pipes/translate.pipe';
import { InputFormatDirective } from '../../../../../../../evolutics-shared-lib/src/lib/Shared/directives/input-formatter.directive';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { InputBasicComponent } from 'ets-fe-ng-sdk';
import { AsyncPipe } from '@angular/common';
import { AutocompleteComponent } from 'ets-fe-ng-sdk';
import { ValidationMessageComponent } from 'ets-fe-ng-sdk';
import { FunctionCaller1 } from 'ets-fe-ng-sdk';
import { ICodeTitle, IStrictFormGroup } from '@Shared/models/index.model';
import { IClientIndividual } from '../individual-client-extras/individual-client.interface';

@Component({
    selector: 'app-employinfo',
    templateUrl: './employinfo.component.html',
    styleUrls: ['./employinfo.component.scss'],
    imports: [
    FormsModule,
    ReactiveFormsModule,
    FunctionCaller1,
    InputBasicComponent,
    BtnComponent,
    AutocompleteComponent,
    InputFormatDirective,
    AsyncPipe,
    TranslatePipe,
    ValidationMessageComponent
]
})
export class EmployinfoComponent implements OnInit {
  @Input() fc: FormGroup<IStrictFormGroup<IClientIndividual['employmentInformation']>>;
  employmentForm = new FormGroup({});
  sectorList: ICodeTitle[] = [];
  incomeBands: any[] = [];
  employmentStatusList: any[] = [];
  occupationGroupList: any[] = [];
  educationLevels: any[];
  constructor(
    public createIndividualClient: CreateIndividualClientService,
    public clientS: Client2Service,
    public uS: UtilityService
  ) {}

  ngOnInit(): void {
    this.setClientNoValidator();
    this.setSectorList();
    this.setEmploymentStatusList();
    this.seteducationLevels(); 
    this.setIncomeBands();
    this.setOccupationGroupList();
    this.fc?.controls.employerClientNo.valueChanges.pipe(debounceTime(1000)).subscribe((r) => {
      this.clientS.getClientNameByNum(r).subscribe((res) => {
        this.fc.patchValue({
          companyName: res,
        });
      });
    });
  }
  get validation() {
    return this.fc?.controls;
  }
  get companyName() {
    return this.fc?.get('companyName')?.value;
  }

  setClientNoValidator() {
    this.fc.get('employerClientNo').setAsyncValidators(this.validateClientNo.bind(this));
  }

  onSubmit() {
    console.log(this.employmentForm);
    this.createIndividualClient.employmentInfo(this.employmentForm.value);
  }
  changeTab(tab: any) {
    console.log('tab');
    this.createIndividualClient.tabChange(tab);
  }

  setSectorList() {
    this.createIndividualClient.getSectorList().subscribe((res) => {
      this.sectorList = res;
      console.log('sectorList', res);
    });
  }
  setEmploymentStatusList() {
    this.createIndividualClient.getEmploymentStatusList().subscribe((res) => {
      this.employmentStatusList = res;
      console.log('employmentStatusList', res);
    });
  }
  seteducationLevels() {
    this.createIndividualClient.seteducationLevels().subscribe((res) => {
      // debugger;
      this.educationLevels = res;
    });
  } 
  setIncomeBands() {
    this.createIndividualClient.getIncomeBands().subscribe((res) => {
      this.incomeBands = res;
    });
  }
  setOccupationGroupList() {
    this.createIndividualClient.getOccupationGroupList().subscribe((res) => {
      this.occupationGroupList = res;
      console.log('setOccupationGroupList', res);
    });
  }

  validateClientNo = (control: AbstractControl) => {
    if (!control.value) return new Promise<any>((res) => res(null));
    else
      return new Promise<any>((res) => {
        this.clientS
          .getClientViewData(control.value)
          .toPromise()
          .then((r) => {
            if (r) this.fc.controls._employerFullname.patchValue(r?.fullName);
            res(r ? null : { notFound: true });
          })
          .catch((e) => {
            res({ notFound: true });
          });
      });
  };
}

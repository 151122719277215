import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, FormBuilder, FormControl, FormControlOptions, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Client2Service } from 'projects/evolutics-client-ui/src/app/Services/client.service';
import { UtilityService } from '@Services/utility.service';
import { EValidationType } from '@Shared/models/index.model';
import { CreateCorporateClientComponent } from '../../create-corporate-client/common/common.component';
import { ICorporateClient } from '../../create-corporate-client/corporate-client-extras/corporate-client.interface';
import { CreateIndividualClientComponent } from '../allforms/create-individual-client.component';
import { CreateIndividualClientService } from '../create-individual-client.service';
import { IClientIndividual } from '../individual-client-extras/individual-client.interface';
import { CreateNewClientModalComponent } from '../../create-corporate-client/modal/new-client-modal/create-new-client-modal.component';
import { ToAnyPipe } from 'ets-fe-ng-sdk';
import { ValidationMessageComponent } from 'ets-fe-ng-sdk';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { InputBasicComponent } from 'ets-fe-ng-sdk';


@Component({
    selector: 'app-nextofkin',
    templateUrl: './nextofkin.component.html',
    styleUrls: ['./nextofkin.component.scss'],
    imports: [
    FormsModule,
    ReactiveFormsModule,
    InputBasicComponent,
    BtnComponent,
    ValidationMessageComponent,
    ToAnyPipe
]
})
export class NextofkinComponent implements OnInit {
  @Input() form = new UntypedFormArray([]);
  @Output() findClient = new EventEmitter(); 
  relationList: any[] = [];
  eValidationType = EValidationType;
  constructor(
    private allFormService: CreateIndividualClientService,
    public clientS: Client2Service,public uS: UtilityService,
  ) {}

  ngOnInit(): void { 
    this.addContactInfoForm();
    this.getRelationList();
  }
  showClient(clientNo) {}
  validation(i: number) {
    return (this.form.controls[i] as FormGroup).controls;
  }
  patchClientInfo(control: AbstractControl) {
    return new Promise<any>((resolve) => {
      if (!control?.value) {
        resolve(null);
      } else
        setTimeout(() => {
          this.clientS.getClientBasicDataLiteByNo(control?.value).subscribe(
            (r) => {
              if (r?.clientNo) {
                (<FormGroup>control.parent).patchValue({
                  dob: r.dateOfBirth,
                  email: r.email,
                  fullName: r.fullName,
                  phoneNumber: r.phone,
                });
                resolve(null);
              } else {
                resolve({ notFound: true });
              }
            },
            (e) => {
              resolve({ notFound: true });
            }
          );
        }, 1000);
    });
  } 
  addContactInfoForm(index = this.form.controls.length) {
    this.form.insert(index, this.newContactInfoForm());
  }
  newContactInfoForm() {
    const form = new FormGroup({
      dob: new FormControl(),
      email: new FormControl(),
      fullName: new FormControl(),
      phoneNumber: new FormControl(),
      relationship: new FormControl(),
      relClientNo: new FormControl(null, null, this.patchClientInfo.bind(this)),
    });
    return form;
  }
  onDeleteContact(index: any) {
    this.form?.removeAt(index);
  }

  changeTab(tab: any) {
    console.log('tab');
    this.allFormService.tabChange(tab);
  }

  getRelationList() {
    this.allFormService.getRelationList().subscribe((res) => {
      this.relationList = res;
    });
  }
  /**
   * open a dialog to create new client
   */
  openCreateClient(control) {
    this.uS.dialogOpener(
      CreateNewClientModalComponent,
      {
        data: {
          useEndorsement: false
        },
        width: '75vw',
        maxWidth: '80vw',
        height: 'auto',
        maxHeight: '80vw',
      },
      (r) => {
        control.patchValue(r.clientNo);
      }
    );
  }
}

import { Component, OnInit, computed, signal } from '@angular/core';
import { ISearchFormSchema } from '@Reusables/reusable-comps/find-item/find-item.model';
import { UserService } from '@Services/user.service';
import { TableCol } from '@Shared/models/index.model';
import { Validators } from '@angular/forms';
import { CodeService } from 'projects/evolutics-client-ui/src/app/Services/code.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FindProviderService } from './find-provider-extras/find-provider.service';
import { UtilityService } from '@Services/utility.service';
import { Client2Service } from 'projects/evolutics-client-ui/src/app/Services/client.service';
import { FindItemComponent } from '../../../../../../../evolutics-shared-lib/src/lib/Reusables/reusable-comps/find-item/find-item.component';


import { PageTemplateComponent } from '@Shared/components/page-template/page-template.component';
@Component({
    selector: 'app-find-provider',
    templateUrl: './find-provider.component.html',
    styleUrls: ['./find-provider.component.scss'],
    imports: [FindItemComponent]
})
export class FindProviderComponent extends PageTemplateComponent {
  readonly displayedColumns = signal<TableCol[]>([
    {
      f: 'no',
      t: 'Provider Number',
      routeFormatter: this.isModal()
        ? null
        : (row) => '../view-provider?providerNo=' + (row?.providerNo || row?.no),
      action: this.isModal() ? this.modalOnComplete : null,
    },
    {
      f: 'clientNo',
      t: 'Client Number',
      routeFormatter: this.isModal() ? null : (row) => '../../view-client?clientNo=' + row.clientNo,
      action: this.isModal() ? this.modalOnComplete : null,
    },
    { f: 'fullName', t: 'Provider Name' },
    { f: 'providerCategory', t: 'Category' },
    { f: 'providerSubCategory', t: 'Sub-Category' },
    { f: 'network', t: 'Network' },
    { f: 'status', t: 'Status' },
    { f: 'createdBy', t: 'Created By' },
  ]);
  loading: boolean;
  usersList: any[];
  categoryList: any[];
  subCategoryList: any[];
  disciplineList: any[];
  networkList: any[];
  readonly schema = computed<ISearchFormSchema[]>(() => [
    {
      field: 'providerNo',
      label: 'Provider No',
      type: 'text',
      standalone: true,
      validators: [Validators.required],
      asyncValidators: [this.findClientService.providerNoValidatorLite],
      action: this.openProvider,
    },
    {
      field: 'location',
      label: 'Location',
      type: 'text',
    },
    {
      field: 'providerName',
      label: 'Provider Name',
      type: 'text',
    },
    {
      field: 'providerCategory',
      label: 'Category',
      type: 'select',
      optionsInitFunc: this.codeService.getCodesByCodeSubGroup('PROVIDER_CAT'),
      valueField: 'code',
      labelType: 'cdt',
    },
    {
      field: 'providerSubCategory',
      label: 'Sub Category',
      type: 'select',
      optionsInitFunc: this.codeService.getCodesByCodeSubGroup('PROVIDER_SUBCAT'),
      valueField: 'code',
      labelType: 'cdt',
    },
    {
      field: 'clientNo',
      label: 'Client Number',
      type: 'text',
      asyncValidators: [this.findClientService.validateClientNoLite('find-provider')],
    },
    {
      field: 'createdBy',
      label: 'Created By',
      type: 'autocomplete',
      optionsFunc: this.userS.searchUsersByName,
      // optionsInitFunc: this.userS.getAllUserCodeAndFullName(),
      labelType: 'uf',
      valueField: 'userName',
    },
    { field: 'createDateFrom', label: 'Created Date From', type: 'date' },
    { field: 'createDateTo', label: 'Created Date To', type: 'date' },
    {
      field: 'discipline',
      label: 'Discipline',
      type: 'select',
      optionsInitFunc: this.codeService.getCodesByCodeSubGroup('DISCIPLINE'),
      valueField: 'code',
      labelType: 'cdt',
    },
    {
      field: 'network',
      label: 'Network',
      type: 'select',
      optionsInitFunc: this.codeService.getCodesByCodeSubGroup('NETWORK_CAT'),
      valueField: 'code',
      labelType: 'cdt',
    },
  ]);

  insuranceTypes: any[] = [];
  productClasses: any[] = [];
  productCodes: any[] = [];
  constructor(
    public userS: UserService,
    public findClientService: Client2Service,
    private codeService: CodeService,
    public route: ActivatedRoute,
    public router: Router,
    private findProviderService: FindProviderService,
    public uS: UtilityService,
  ) {
    super();
    console.log(this.isModal());
  }

  readonly search = signal((query: any) => this.findProviderService.search(query));

  openProvider = (row: any) => {
    console.log(row);
    if (this.isModal()) {
      this.modalOnComplete(row);
    } else {
      this.router.navigate(['../view-provider'], {
        relativeTo: this.route,
        queryParams: { providerNo: row?.providerNo || row?.no },
      });
    }
  };
  openClient(row: any) {
    console.log(row);
    if (this.isModal()) {
      this.modalOnComplete(row);
    } else {
      this.router.navigate(['../view-provider'], {
        relativeTo: this.route,
        queryParams: { clientNo: row?.clientNo },
      });
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// import { ClientService } from '@Services/client.service';
import { UserService } from '@Services/user.service';
import { UtilityService } from '@Services/utility.service';
import { FindClientService } from './find-client.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { ISearchFormSchema } from '@Reusables/reusable-comps/find-item/find-item.model';
import { TableCol } from '@Shared/models/index.model';
import { EClientType } from '../client-extras/client.interface';
import { PageTemplateComponent } from '@Shared/components/page-template/page-template.component';
import { AgentService } from 'projects/evolutics-client-ui/src/app/Services/agent.service';
import { CodeService } from '@Services/code.service';
import { Client2Service } from 'projects/evolutics-client-ui/src/app/Services/client.service';
import { FindItemComponent } from '../../../../../../evolutics-shared-lib/src/lib/Reusables/reusable-comps/find-item/find-item.component';

import { LoaderComponent } from 'ets-fe-ng-sdk';

@Component({
    selector: 'app-find-client',
    templateUrl: './find-client.component.html',
    styleUrls: ['./find-client.component.scss'],
    imports: [LoaderComponent, FindItemComponent]
})
export class FindClientComponent extends PageTemplateComponent implements OnInit {
  displayedColumns: TableCol[];
  loading: boolean;
  // usersList: any[];
  schema: ISearchFormSchema[];
  constructor(
    public findClientService: FindClientService,
    public router: Router,
    public userS: UserService,
    public route: ActivatedRoute,
    public uS: UtilityService,
    public agentS: AgentService,
    private cS: CodeService,
    public clientS: Client2Service,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    // debugger
    this.loading = true;
    this.displayedColumns = [
      {
        f: 'clientNo',
        t: 'Client Number',
        routeFormatter: this.isModal()
          ? null
          : (client) => {
              return '../view-client?clientNo=' + client.clientNo;
            },
        action: this.isModal() ? this.modalOnComplete : null,
      },
      { f: 'fullName', t: 'Name' },
      {
        f: 'type',
        t: 'Type',
        formatter: (val) => {
          if (val == EClientType.individual) return 'Individual';
          else if (val == EClientType.corporate) return 'Corporate';
          else return val;
        },
      },
      { f: 'createdOn', t: 'Created On', formatter: this.uS.fullDateTime },
      { f: 'createdBy', t: 'Created By' },
    ];

    this.schema = [
      {
        field: 'clientNo',
        label: 'Client Number',
        type: 'text',
        standalone: true,
        validators: [Validators.required],
        nonCompact: true,
        asyncValidators: [this.findClientService.clientNoValidatorLite],
        action: (form, cell) => {
          this.isModal() ? this.modalOnComplete(form) : this.openClient(cell);
        },
      },
      { field: 'name', label: 'Name', type: 'text', nonCompact: true },
      { field: 'phoneNo', label: 'Phone Number', type: 'text' },
      { field: 'email', label: 'Email', type: 'text' },
      {
        field: 'enrolee',
        label: 'Enrollee',
        type: 'text',
        standalone: true,
        validators: [Validators.required],
      },
      {
        field: 'externalRef',
        label: 'External Ref.',
        type: 'text',
      },
      {
        field: 'penComNo',
        label: 'Pencom Number',
        type: 'text',
        asyncValidators: [this.validatePenComm],
      },
      {
        field: 'bvn',
        label: 'BVN',
        type: 'text',
        asyncValidators: [this.validateBVN],
      },
      {
        field: 'nin',
        label: 'NIN',
        type: 'text',
        asyncValidators: [this.validateNIN],
      },
      {
        field: 'tin',
        label: 'TIN',
        type: 'text',
        asyncValidators: [this.validateTIN],
      },
      {
        field: 'idNo',
        label: 'ID Number',
        type: 'text',
        asyncValidators: [this.validateidNumber],
      },
      {
        field: 'staffNo',
        label: 'Staff Number',
        type: 'text',
        asyncValidators: [this.validateStaffNumber],
      },
      {
        field: 'status',
        label: 'Client Status',
        type: 'select',
        optionsInitFunc: this.cS.getCodesByCodeSubGroup('CLIENT_STATUS'),
        labelType: 'ct',
        valueField: 'code',
      },
      {
        field: 'sourceCat',
        label: 'Source Category',
        type: 'select',
        optionsInitFunc: this.cS.getCodesByCodeSubGroup('CLIENT_SOURCE_CAT'),
        labelType: 'ct',
        valueField: 'code',
      },
      {
        field: 'source',
        label: 'Source',
        type: 'text',
        asyncValidators: [this.validateSource],
      },
      {
        field: 'type',
        label: 'Client Type',
        type: 'select',
        options: [
          { code: EClientType.individual, title: 'Individual' },
          { code: EClientType.corporate, title: 'Corporate' },
        ],
        valueField: 'code',
        labelField: 'title',
      },
      {
        field: 'createdBy',
        label: 'Created By',
        type: 'autocomplete',
        // optionsInitFunc: this.userS.getAllUserCodeAndFullName(),
        optionsFunc: this.userS.searchUsersByName,
        labelType: 'uf',
        valueField: 'userName',
      },
      { field: 'createDateFrom', label: 'Created Date From', type: 'date' },
      { field: 'createDateTo', label: 'Created Date To', type: 'date' },
    ];
    this.loading = false;
  }
  search = (query) =>
    this.findClientService.search(query).pipe(
      map((r) => ({
        content: r?.content || [],
        total: r?.totalElements || 0,
      })),
    );

  openClient(clientNo: string) {
    this.router.navigate(['../view-client/'], {
      relativeTo: this.route,
      queryParams: { clientNo },
    });
  }

  openProvider(providerNo?: string) {
    this.router.navigate(['../view-provider/'], {
      relativeTo: this.route,
      queryParams: { providerNo },
    });
  }

  validateProvider = (control: FormControl) => {
    const providerNo = control?.value;
    if (!providerNo) {
      return of({ notFound: true });
    }
    return this.findClientService.getClientNoByProviderNo(providerNo).pipe(
      map((clientNo) => (!clientNo ? throwError('No clientNo for providerNo') : clientNo)), // a clientNo is returned or not
      mergeMap((clientNo) => this.findClientService.getClientList(clientNo)),
      map((client) => (!client ? throwError('No client found') : client)), // the clientNo returns a client object or not

      map(() => null), // return null to validate the input
      catchError((err) => {
        console.log('Error validating provider no: ' + providerNo, err);
        return of({ notFound: true }); // return error object to invalidate the input
      }),
    );
  };

  /**
   * validate pension commission number if exist
   * @param control penComNo control
   * @returns  promise
   */
  validatePenComm = (control: FormControl) => {
    return new Promise<any>((res) => {
      if (!control.value) res(null);
      else
        this.clientS
          .checkIfClientExistsBy({
            pensionCommissionNumber: control.value,
          })
          .toPromise()
          .then((r: boolean) => (r ? res(null) : res({ notExist: true })))

          .catch(() => res({ notExist: true }));
    });
  };

  /**
   * validate bvn number if exist
   * @param control bvn control
   * @returns  promise
   */
  validateBVN = (control: FormControl) => {
    return new Promise((res) => {
      if (!control.value) res(null);
      else
        this.clientS
          .checkIfClientExistsBy({
            bvn: control.value,
          })
          .toPromise()
          .then((r) => (r ? res(null) : res({ notExist: true })))
          .catch(() => res({ notExist: true }));
    });
  };

  /**
   * validate nin number if exist
   * @param control nin control
   * @returns  promise
   */
  validateNIN = (control: FormControl) => {
    return new Promise((res) => {
      if (!control.value) res(null);
      else
        this.clientS
          .checkIfClientExistsBy({
            nationalInsuranceNumber: control.value,
          })
          .toPromise()
          .then((r) => (r ? res(null) : res({ notExist: true })))
          .catch(() => res({ notExist: true }));
    });
  };

  /**
   * validate tin number if exist
   * @param control tin control
   * @returns  promise
   */
  validateTIN = (control: FormControl) => {
    return new Promise((res) => {
      if (!control.value) res(null);
      else
        this.clientS
          .checkIfClientExistsBy({
            tin: control.value,
          })
          .toPromise()
          .then((r) => (r ? res(null) : res({ notExist: true })))
          .catch(() => res({ notExist: true }));
    });
  };

  /**
   * validate id number if exist
   * @param control idNumber control
   * @returns  promise
   */
  validateidNumber = (control: FormControl) => {
    return new Promise((res) => {
      if (!control.value) res(null);
      else
        this.clientS
          .checkIfClientExistsBy({
            idNumber: control.value,
          })
          .toPromise()
          .then((r) => (r ? res(null) : res({ notExist: true })))
          .catch(() => res({ notExist: true }));
    });
  };

  /**
   * validate statff no
   * @param control staffNo control
   * @returns  promise
   */
  validateStaffNumber = (control: FormControl) => {
    return new Promise((res) => {
      if (!control.value) res(null);
      else
        this.clientS
          .searchClientEmployment({
            staffNo: control.value,
          })
          .toPromise()
          .then((r) => (r?.content?.length ? res(null) : res({ notExist: true })))
          .catch(() => res({ notExist: true }));
    });
  };

  /**
   * validate form source control
   * @param control source form control
   * @returns  promise
   */
  validateSource = (control: FormControl) => {
    if (!control.value || !control.parent.value.sourceCat) return new Promise((res) => res(null));
    if (control.parent.value.sourceCat == 'A') return this.agentS.validateAgentNoLite(control);
    if (control.parent.value.sourceCat == 'R') return this.clientS.validateClientNo(control);
    if (control.parent.value.sourceCat == 'U')
      return this.userS
        .checkIfClientExistsBy({ userName: control.value })
        .toPromise()
        .then((res) => {
          if (!res) return { notFound: true };
          return null;
        })
        .catch((err) => {
          return { notFound: true };
        });

    return new Promise((res) => res(null));
  };
}

<loader [loading]="loading">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-md text-center">
        <label class="text-primary py-2">{{ 'Account Type' | appTranslate | async }}</label>
        <br />
        <div class="row align-items-center">
          <div class="col">
            <app-btn (mclick)="showBankModel()" [type]="bankModel ? 'primary' : 'outline'">Bank</app-btn>
          </div>
          <div class="col-auto">
            {{ 'Or' | appTranslate | async }}
          </div>
          <div class="col">
            <app-btn (mclick)="showMobileModel()" [type]="mobileModel ? 'primary' : 'outline'">
              Mobile Money
            </app-btn>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <div class="row align-items-end">
      <div class="col-md-6">
        <app-autocomplete
          label="Bank Country"
          [stacked]="true"
          [form]="form"
          [options]="countries()"
          labelType="cd"
          name="country"
          valueField="code"
      />
        <app-validation-message [control]="form.controls.country" label="Bank country" />
      </div>

      @if (bankModel) {
        <div class="col-md-6">
          <app-autocomplete
            label="Bank Name"
            [form]="form"
            name="bankCode"
            [stacked]="true"
            valueField="code"
            labelType="cd"
            [options]="bankS.getBanksByCountry | httpListCaller1: countryFormValue() | async"
            (mSelectOptionChange)="setBankNameDesc($event)"
            [disabled]="!countryFormValue()" />
          <app-validation-message />
        </div>

        <div class="col-md-6">
          <div class="row align-items-center">
            <div class="col">
              <label class="text-primary">{{ 'Sort Code' | appTranslate | async }}</label>
              <input type="text" [value]="sortCode" class="form-control control-bg-gray" readonly />
            </div>
            <div class="col-auto">
              <label class="text-primary">&nbsp;</label>
              <app-button
                [buttonType]="'find'"
                [text]="'Find Sort Code'"
                (click)="openSortCodeModal()"></app-button>
            </div>
          </div>
          <app-validation-message />
        </div>

        <div class="col-md-6">
          <app-input-basic
            label="Bank Branch"
            [form]="form"
            name="bankBranch"
            type="viewer"
            [disabled]="!countryFormValue()"
            #bankBranchTag />
          <app-validation-message />
        </div>

        <div class="col-md-6">
          <app-input-basic
            label="Account Number"
            [form]="form"
            name="accountNo"
            type="number"
            [stacked]="true"
            [noFormat]="true"
            [disabled]="!countryFormValue()"
            [showValidation]="true"
            #accountNo />
          <app-validation-message
            [control]="accountNo.control()"
            label="Account number"
            [maxLength]="+countryData()?.bankAccountMaxLength"
            [minLength]="+countryData()?.bankAccountMinLength"></app-validation-message>
        </div>

        <div class="col-md-6">
          <app-input-basic
            label="Account Name"
            [form]="form"
            name="accountName"
            type="text"
            [stacked]="true"
            [clearOnDisable]="false"
            [noFormat]="true"
            [readonly]="!countryFormValue()"
            #accountName />
          <app-validation-message [control]="accountName.control()" label="Account name" />
        </div>
        @if (!hideBlocked && !isSalesPortal) {
          <div class="col-md-6 mb-4">
            <app-input-basic
              label="Blocked"
              [form]="form"
              name="blocked"
              type="checkbox"
              [stacked]="true"
              [disabled]="!countryFormValue()"
              #blocked />
          </div>
        }

        <div class="col-md-6">
          <app-file-upload
            label="Upload Proof of Account"
            accept="image"
            [(file)]="accProofFile"
            [disabled]="!countryFormValue()"></app-file-upload>
          <app-validation-message />
        </div>
      }

      @if (mobileModel) {
        <div class="col-md-6">
          <app-input-basic
            label="Provider"
            [form]="form"
            name="bankCode"
            type="select"
            [stacked]="true"
            valueField="code"
            labelType="ct"
            [options]="accS.getProviderList | functionCaller | async"
            (mSelectOptionChange)="setBankNameTitle($event)"
            [disabled]="!countryFormValue()"
            #bankName />
          <app-validation-message [control]="bankName.control()" label="Provider" />
        </div>
        <div class="col-md-6">
          <app-input-basic
            label="Account Number"
            [form]="form"
            name="accountNo"
            type="text"
            [stacked]="true"
            [disabled]="!countryFormValue()"
            #accountNo />
          <app-validation-message
            [control]="accountNo.control()"
            label="Account number"
            [maxLength]="countryData()?.bankAccountMaxLength | toAny"
            [minLength]="countryData()?.bankAccountMinLength | toAny"></app-validation-message>
        </div>
      }
    </div>
    @if (showSubmissionBtn) {
      <div class="d-lg-flex justify-content-between mt-2">
        <div class="text-danger">
          {{ message }}
        </div>
        <app-btn (mclick)="submit()" icon="save" type="secondary">Save</app-btn>
      </div>
    }
  </form>
</loader>

import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { IClientProviderPost } from '../Life/client-desk/client-provider/client-provider-extras/client-provider.interface';
import { CreateCorporateClientComponent } from '../Life/client-desk/create-corporate-client/common/common.component';
import { ICorporateClient } from '../Life/client-desk/create-corporate-client/corporate-client-extras/corporate-client.interface';
import { CreateIndividualClientComponent } from '../Life/client-desk/create-individual-client/allforms/create-individual-client.component';
import { IClientIndividual } from '../Life/client-desk/create-individual-client/individual-client-extras/individual-client.interface';
import { FindClientComponent } from '../Life/client-desk/find-client/find-client.component';
import { IClientNewViewData, IClientViewData } from '@Shared/models/client-desk.interface';
import { ApiService } from '@Services/api.service';
import { UtilityService } from '@Services/utility.service';
import { Client1Service as CS } from '@Services/client.service';
import { EndorsementResponse } from '@Reusables/reusable-pages/endorsement/endorsement-extras/endorsement.model';
import { FindProviderComponent } from '../Life/client-desk/client-provider/find-provider/find-provider.component';
import { environment } from '@environments/environment';
import { ICodeTitle, ISearchResponse2 } from '@Shared/models/index.model';
import { forkJoin, lastValueFrom, of } from 'rxjs';
import { uniqBy } from 'lodash-es';
import { HelpersService } from '../Life/client-desk/service/helpers.service';
import { AppService } from '@Services/app.service';
import { EClientType, IClientEmployer, IClientKYC } from '../Life/client-desk/client-extras/client.interface';
import { UsersService } from '@User/adminuser/user-extras/users.service';
import { ICountryData } from '@Shared/models/location.interface';
import { IPolicy } from '../Life/policy-desk/policy-desk-extras/policy-desk.model';
import {
  FindProviderService,
  IProviderSR,
  ISearchProvider,
} from '../Life/client-desk/client-provider/find-provider/find-provider-extras/find-provider.service';
import { ISearchResponse } from '@Shared/components/search/search-extras/search.interface';

@Injectable({
  providedIn: 'root',
})
export class Client2Service extends CS {
  baseURL2 = environment.apiBaseUrl + '/rest/';
  constructor(
    public apiService: ApiService,
    public uS: UtilityService,
    private appService: AppService,
    private userS: UsersService,
    private helpersService: HelpersService,
    public findProviderService: FindProviderService,
  ) {
    super(apiService, uS);
  }

  getGeolocation(arg0: { address: string; country?: string; region?: string }) {
    return this.apiService
      .get<{
        data: {
          administrativeArea: string;
          confidence: number;
          continent: string;
          country: string;
          country_code: string;
          county: string;
          label: string;
          latitude: string;
          locality: string;
          longitude: string;
          map_url: string;
          name: string;
          neighbourhood: string;
          number: string;
          postal_code: string;
          region: string;
          regionCode: string;
          street: string;
          type: string;
          _latLong?: string;
        }[];
      }>(this.baseURL2 + 'client/geolocation', arg0)
      .pipe(
        map((r) => {
          r?.data?.forEach((x) => {
            x._latLong = `${x.latitude}, ${x.longitude}`;
          });
          return r;
        }),
      );
  }
  getIdTypesList() {
    return this.apiService.get(this.baseURL2 + 'codes/sub/group/ID_TYPE');
  }

  getClientContactPersons(clientNo: string) {
    return this.apiService.get(this.baseURL2 + 'crm/client/view/contact/person/' + clientNo);
  }
  // getClientType(clientNo: string) {
  //   return this.apiService.get(this.baseURL2 + 'client/type/' + clientNo);
  // }

  getProviderByClientNoAndSubCat(clientNo: string, providerSubCatCode: string) {
    return this.apiService
      .get<boolean>(this.baseURL2 + `client/check/existing/provider/${clientNo}/${providerSubCatCode}`)
      .pipe(
        map((r) => {
          return { status: r };
        }),
      );
  }
  getEndorsements(data: any) {
    return this.apiService.get(this.baseURL2 + 'client/endorsements/' + data);
  }
  getWebflows(data: any) {
    return this.apiService.get(this.baseURL2 + 'workflows/client/' + data);
  }
  getWebLogin(data: any) {
    return this.apiService.get(this.baseURL2 + 'client/web_access/' + data);
  }
  getPolicies() {
    return this.apiService.get(this.baseURL2 + 'client/policies/');
  }
  getEmailList(query: {
    listSource: 'BEN' | 'CI' | 'CLA' | 'PAY' | 'POL';
    quoteNo?: string;
    claimNo?: string;
  }) {
    return this.apiService.get(this.baseURL2 + 'client/email-list', query);
  }
  getPoliciesApi(clientNo: string, busLine: string) {
    return this.apiService.get<{
      policies: IPolicy[];
      policyGroupLists?: any;
    }>(this.baseURL2 + 'policy/owned/client', {
      busLine: environment.useBusline ? busLine : '',
      ownerClientNo: clientNo,
    });
  }
  getBusinessApi(id1: string, busLine: string) {
    return this.apiService
      .get(this.baseURL2 + 'policy/owned/client/' + id1)
      .pipe(filter((item) => item.busLine != busLine));
  }
  getRelationshipApi(clientNo) {
    return this.apiService.get(this.baseURL2 + 'client/related/clients/' + clientNo);
  }
  createClientRelationship(data) {
    return this.apiService.post(this.baseURL2 + 'client/related/client', data);
  }
  updateClientRelationship(id, data) {
    return this.apiService.put(this.baseURL2 + 'client/related/client/' + id, data);
  }
  getPendingPaymentsApi(id1, id2) {
    return this.apiService.get(this.baseURL2 + 'client/pending/payment/adjustment/' + id1 + '/' + id2);
  }
  getPendingPayouts(clientNo) {
    return this.apiService.get(this.baseURL2 + 'payout/client/pending-payout/' + clientNo).pipe(
      map((res) => res),
      catchError(() => of([])),
    );
  }
  getClientAccounts(clientNo) {
    return this.apiService.get(this.baseURL2 + `client/account/${clientNo}`);
  }

  computeWeightedAverageAge(
    data: {
      age?: number;
      coverCode: string;
      dateOfBirth?: string;
      sumAssured: number;
    }[],
  ) {
    return this.apiService.post<number>(this.baseURL + 'compute/weighted-average-age', data).pipe(
      map((res) => res),
      catchError(() => of(null)),
    );
  }
  getPendingPayoutsCount(clientNo) {
    return this.getPendingPayouts(clientNo).pipe(map((r) => r.reduce((a, c) => a + c.intialAmt, 0)));
  }

  getPendingQuotes() {
    return this.apiService.get(this.baseURL2 + 'client/pending/quote/');
  }
  getPendingQuotesApi(clientNo: string, busLine: string) {
    return this.apiService.get(this.baseURL2 + 'client/pending/quote/' + clientNo + '?busLine=' + busLine);
  }
  getClientReferrals(query) {
    return this.apiService.get(this.baseURL2 + 'client/referral/search', query);
  }
  createClientReferrals(query) {
    return this.apiService.post(this.baseURL2 + 'client/referral', query);
  }
  updateClientReferrals(query, id) {
    return this.apiService.put(this.baseURL2 + `client/referral/${id}`, query);
  }
  deleteClientReferrals(id) {
    return this.apiService.delete(this.baseURL2 + `client/referral/${id}`);
  }
  getOccupationList = (data?: string) => {
    // debugger
    return this.apiService.get<ICodeTitle[]>(this.baseURL2 + 'codes/sub/category/OCCUPATION/');
  };
  getOccupationGroupList() {
    return this.apiService.get<ICodeTitle[]>(this.baseURL2 + 'codes/sub/group/OCCUPATION_GROUP');
  }
  getPendingQuotesByOwnerClientNo(clientNo: string) {
    return this.apiService.get(this.baseURL2 + 'policy/days/quote/' + clientNo);
  }
  getDocument(data: any) {
    return this.apiService.get(this.baseURL2 + 'document/client/' + data);
  }

  searchProvider(queryForm) {
    return this.apiService.get(this.baseURL2 + '' + queryForm);
  }
  getProviderCategories() {
    return this.apiService.get(this.baseURL2 + 'codes/sub/group/PROVIDER_CAT');
  }
  getProviderSubCategories(providerCategory) {
    return this.apiService.get(this.baseURL2 + 'codes/sub/category/PROVIDER_SUBCAT/' + providerCategory);
  }
  getClientProviderName(providerNo) {
    return this.apiService.get<{
      fullName: string;
      no: string;
    }>(this.baseURL2 + 'client/provider/no/fullName/' + providerNo);
  }
  getClientCategoryStatuses(clientNo: string) {
    return this.apiService.get<
      {
        agentStatus?: string;
        clientStatus?: string;
        providerStatus?: string;
        userStatus?: string;
      }[]
    >(this.baseURL2 + 'client/categories/status/' + clientNo);
  }
  getnameList(data: any) {
    return this.apiService.get(this.baseURL2 + 'client/search?name=' + data);
  }
  getphoneList(data: any) {
    return this.apiService.get(this.baseURL2 + 'client/search?phoneNo=' + data);
  }
  getemailList(data: any) {
    return this.apiService.get(this.baseURL2 + 'client/search?email=' + data);
  }

  getExternalRefList(data: any) {
    return this.apiService.get(this.baseURL2 + 'client/search?externalRef=' + data);
  }
  getCreatedByList(data: any) {
    return this.apiService.get(this.baseURL2 + 'client/search?createdBy=' + data);
  }
  getCreatedToo(data: any) {
    return this.apiService.get(this.baseURL2 + 'client/search?createDateTo=' + data);
  }
  getCreatedFrom(data: any) {
    return this.apiService.get(this.baseURL2 + 'client/search?createDateFrom=' + data);
  }
  getCodeList = (cat: string) => {
    return this.apiService.get<ICodeTitle[]>(this.baseURL2 + 'codes/sub/category/' + cat);
  };

  getSubProviderTypesList(subGroup: string, providerType: string) {
    return this.apiService.get<ICodeTitle[]>(
      this.baseURL2 + 'codes/sub/category/' + subGroup + '/' + providerType,
    );
  }

  submitProvider(data: any) {
    return this.apiService.post(this.baseURL2 + 'client/create/provider', data);
  }

  protected adjustNewFormatToOld(c: IClientNewViewData): IClientViewData {
    if (!c) return null;
    let obj = c as any;
    obj.age = c.countData.age;
    obj.sumAtRisk = c.countData.sumAtRisk;
    const keysToCheck = ['clientContact', 'clientDetails', 'clientDates'];
    const keysToIgnore = ['createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'clientNo', 'id'];
    for (const k of keysToCheck) {
      const cObject = c[k];
      for (const key in cObject) {
        if (obj[key] || keysToIgnore.includes(key)) continue;
        else {
          // debugger;
          obj[key] = cObject[key];
        }
      }
    }
    // debugger
    return obj as IClientViewData;
  }
  protected setCategory(c: IClientViewData): IClientViewData {
    if (!c) return null;

    c.category = uniqBy(c.categoryList, 'category')
      .map((x) => x.category)
      .join('/');
    return c;
  }

  protected getFullname(clientData: any) {
    clientData.fullName =
      clientData?.fullName ||
      `${clientData.firstName || ''} ${clientData.middleName || ''} ${clientData.surname || ''}`;
    clientData.fullName = clientData.fullName?.trim()?.replace('  ', ' ');
    return clientData;
  }
  getClientPicture(clientNo: string) {
    return this.apiService.get(`${this.baseURL2}document/view/client/other_kyc/${clientNo}/PP`).pipe(
      map((docs) => (Array.isArray(docs) ? docs : [])),
      map((docs) =>
        docs.find((doc) => this.helpersService.isPictureFormat(doc?.fileName) && doc?.docKey != null),
      ),
    );
  }

  getClientSignature(clientNo: string) {
    return this.apiService.get(`${this.baseURL2}document/view/client/other_kyc/${clientNo}/SI`).pipe(
      map((docs) => (Array.isArray(docs) ? docs : [])),
      map((docs) =>
        docs.find((doc) => this.helpersService.isPictureFormat(doc?.fileName) && doc?.docKey != null),
      ),
    );
  }

  updateClientInfo(id: any, data: any) {
    return this.apiService.put(this.baseURL2 + 'client/individual/', +id, data);
  }

  getClientPassport(clientNo: string) {
    const docBaseURL$ = this.getDocumentsBaseURL();
    const documents$ = this.apiService.get(`${this.baseURL2}document/view/client/other_kyc/${clientNo}/pp`);
    const defaultImage = '/assets/img/avatar.png';

    return forkJoin([docBaseURL$, documents$]).pipe(
      map(([docBaseURL, documents]) => {
        const pp = this.helpersService.extractPassportURL(documents, docBaseURL);
        return pp ? pp : defaultImage;
        // return defaultImage
      }),
      catchError((e) => of(defaultImage)),
    );
  }

  getDocumentsBaseURL() {
    return this.apiService.getText(this.baseURL2 + 'global-options/documentBaseUrl');
  }

  getClientStatusCodes() {
    return this.apiService.get<ICodeTitle[]>(this.baseURL2 + 'codes/sub/category/CLIENT_STATUS');
  }
  getProviderStatusCodes() {
    return this.apiService.get<ICodeTitle[]>(this.baseURL2 + 'codes/sub/category/PROVIDER_STATUS');
  }

  getClientStatus(clientNo) {
    return this.apiService
      .get(this.baseURL2 + 'crm/client/view/gen/info/' + clientNo)
      .pipe(map((res) => res?.statusCd));
  }
  getClientPolicies(clientNo: string) {
    const busLine = this.appService.getCurrentSystemMetadata?.busline;
    return this.apiService.get(`${this.baseURL}policies/${clientNo}/${busLine}`);
  }

  getClientPoliciesDetails(clientNo) {
    return this.apiService.get(this.baseURL2 + `policy/days/quote/${clientNo}`);
  }
  getClientPoliciesDetailsFromAssured(assured) {
    return this.apiService.get(this.baseURL2 + `policy/assured?assured=${assured}`);
  }
  getClaimContent(payload) {
    return this.apiService.get(this.baseURL2 + `policy/covers/content/search`, payload);
  }
  getPolicyCovers(policyNo: string) {
    type IPolicyCoverCD = {
      code: string;
      assured: string;
      coverDescription: string;
    }[];
    return policyNo
      ? this.apiService.get<IPolicyCoverCD>(this.baseURL2 + 'policy/covers/code/desc/' + policyNo)
      : of<IPolicyCoverCD>([]);
  }
  changeClaimStatus(data) {
    return this.apiService.put(this.baseURL2 + 'claim/change/claim/status', data);
  }
  adjustClaim(data) {
    return this.apiService.post(this.baseURL2 + 'claim/adjust/claim', data);
  }
  getPolicyCodeByPolicyNo(policyNo: string) {
    return this.apiService.get(this.baseURL2 + 'policy/code/policy/' + policyNo);
  }
  getPolicyCodesByPolicyNo(policyNo: string) {
    return this.apiService.get(this.baseURL2 + 'policy/code/policy/no/' + policyNo);
  }
  getPolicyNoSuffix(policyNo: string, policyCode: string) {
    return this.apiService.get(
      this.baseURL2 + 'policy/group/list/policyNoSuffix/' + policyCode + '/' + policyNo,
    );
  }
  getPolicyCurrency(policyNo: string, policyCode: string) {
    return this.apiService.get(`${this.baseURL2}policy/${policyNo}/${policyCode}`);
  }
  getStates(regionCode: string) {
    return this.apiService.get(this.baseURL2 + 'location/state/' + regionCode);
  }
  getCountriesList() {
    return this.apiService.getWithLocalCache<ICountryData[]>(this.baseURL2 + 'location/country');
  }
  getRegionsList = (countryCode: string) => {
    return this.apiService.getWithLocalCache(this.baseURL2 + 'location/region/country/' + countryCode);
  };
  getStatesList(regionCode: string) {
    return this.apiService.getWithLocalCache(this.baseURL2 + 'location/state/' + regionCode);
  }
  getTownsList(stateCode: string = '') {
    return this.apiService.getWithLocalCache(`${this.baseURL2}location/town/${stateCode}`);
  }
  getStatesListByCountry(countryCode: string) {
    return this.apiService.getWithLocalCache(this.baseURL2 + 'location/state/country/' + countryCode);
  }
  getClaimTypes(coverCode: string) {
    return this.apiService.get(this.baseURL2 + 'product/covers/perils/claim-type/' + coverCode);
  }
  getClaimTypesList() {
    return this.apiService.get<ICodeTitle[]>(this.baseURL2 + 'codes/sub/category/CLAIM_TYPE');
  }
  getPerils(coverCode: string, claimType: string) {
    return this.apiService.get(
      `${this.baseURL2}product/covers/perils-details/peril-code/${coverCode}/${claimType}`,
    );
  }

  getPerilsByPolicyNoPolicyCodeAndSuffix(claimType, coverCode, policyCode, policyNo, policyNoSuffix = null) {
    let url = `${this.baseURL2}policy/covers/peril?claimType=${claimType}&coverCode=${coverCode}&policyCode=${policyCode}&policyNo=${policyNo}`;
    if (policyNoSuffix) url += `&policyNoSuffix=${policyNoSuffix}`;

    return this.apiService.get(url);
  }

  getPerilsByQuoteNo(claimType, coverCode, quoteNo) {
    let url = `${this.baseURL2}policy/covers/peril/${quoteNo}/${claimType}/${coverCode}`;
    return this.apiService.get(url);
  }

  getPerilsByQuoteNo2(claimType, coverCode, quoteNo) {
    let url = `${this.baseURL2}policy/claim/peril-code?claimType=${claimType}&coverCode=${coverCode}&quoteNo=${quoteNo}`;
    return this.apiService.get(url);
  }

  getProxCauseByPolicyNoPolicyCodeAndSuffix(query, payload?) {
    let url = `${this.baseURL2}policy/covers/peril/prox-cause/description/${query.policyNo}/${query.policyCode}`;
    if (query.policyNoSuffix) url += `/${query.policyNoSuffix}`;
    return this.apiService.get(url, payload);
  }
  getProxCauseByQuoteNo(query, payload?) {
    let url = `${this.baseURL2}policy/covers/peril/details/${query.quoteNo}/${query.perilCode}/${query.coverCode}`;
    return this.apiService.get(url);
  }
  /**
   * get proximity cause using peril code
   * @param query policy object
   * @param perilCode selected peril code
   * @returns proximity cause
   */
  getProxCauseByPerilCode(query, perilCode) {
    let url = `${this.baseURL2}policy/covers/peril?claimType=${query.claimType}&coverCode=${query.coverCode}&perilCode=${perilCode}&policyCode=${query.policyCode}&policyNo=${query.policyNo}`;
    if (query.policyNoSuffix) url += `&policyNoSuffix=${query.policyNoSuffix}`;
    return this.apiService.get(url);
  }
  /**
   * get proximity cause using peril code
   * @param query policy object
   * @param perilCode selected peril code
   * @returns proximity cause
   */
  getProxCauseByPerilCodeAndQuoteNo(query, perilCode) {
    let url = `${this.baseURL2}policy/claim/proximate-cause?claimType=${query.claimType}&coverCode=${query.coverCode}&perilCode=${perilCode}&quoteNo=${query.quoteNo}`;
    return this.apiService.get(url).pipe(map((res) => res.filter((r) => r.proximateCauseDesc)));
  }

  createPerils = (claimNo, data) => this.apiService.post(this.baseURL2 + `claim/peril/${claimNo}`, data);

  updatePerils = (id, claimNo, data) =>
    this.apiService.put(this.baseURL2 + `claim/peril/${id}/${claimNo}`, data);

  getProximateCause(coverCode: string, claimType: string, perilCode: string) {
    return this.apiService.get(
      `${this.baseURL2}product/covers/perils-details/prox-cause/${coverCode}/${claimType}/${perilCode}`,
    );
  }
  checkClaimEstimateStatus(data) {
    return this.apiService.get(this.baseURL2 + 'product/covers/verify/claim/estimate', data);
  }
  postNewClaim(data) {
    return this.apiService.post(this.baseURL2 + 'claim/', data);
  }

  updateClaim(data, claimNo) {
    return this.apiService.put(this.baseURL2 + 'claim/' + claimNo, data);
  }
  postNewHealthClaim(data) {
    return this.apiService.postFile(this.baseURL2 + 'claim/health', data);
  }
  postClaimRequirementReceived({ claimReq, received, receivedBy, claimNo }) {
    return this.apiService.post(
      this.baseURL2 + `claim/received/claim/requirements/${claimReq}/${received}/${receivedBy}/${claimNo}`,
    );
  }
  assignClaimProvider({ providerAction, providerNo, providerInstruction, claimNo }) {
    return this.apiService.post(
      // providerInstruction is encoded in case user typed in / or other troublesome characters
      this.baseURL2 +
        `claim/set/claim/provider/${providerAction}/${providerNo}/${encodeURIComponent(
          providerInstruction,
        )}/${claimNo}`,
    );
  }
  getTariffCodesList() {
    return this.apiService.get(this.baseURL2 + 'tariff/code/desc');
  }
  getAccountsList() {
    return this.apiService.get(this.baseURL2 + 'acc/codes/desc');
  }
  getWorkflowStatusList() {
    return this.apiService.get<ICodeTitle[]>(this.baseURL2 + 'codes/sub/category/WORKFLOW_STATUS');
  }
  getSlaLevelsList() {
    return this.apiService.get<ICodeTitle[]>(this.baseURL2 + 'codes/sub/category/SLA_LEVEL');
  }
  getTotalPremiumReceived(clientNo) {
    return this.apiService.get(this.baseURL2 + 'bank/transactions/amount/sum/' + clientNo);
  }
  getPendingClaims(clientNo) {
    return this.apiService.get(this.baseURL2 + 'claim/client/pending-claims/' + clientNo).pipe(
      map((res) => res),
      catchError(() => of([])),
    );
  }
  getPendingClaimsCount(clientNo) {
    return this.getPendingClaims(clientNo).pipe(
      map((res) => res.reduce((a, c) => a + c.currentEstimate, 0)),
      catchError(() => of([])),
    );
  }
  getNotesList(clientNo: string | number) {
    return this.apiService.get(this.baseURL2 + 'notes/active/CLI/' + clientNo);
  }
  getSocialMediaAccounts(clientNo: string) {
    return this.apiService.get(this.baseURL2 + 'client/social/media/' + clientNo);
  }
  createSocialMediaAccount(data) {
    return this.apiService.post(this.baseURL2 + 'client/social/media', data);
  }
  editSocialMediaAccount(id, data) {
    return this.apiService.put(this.baseURL2 + 'client/social/media/' + id, data);
  }
  deleteSocialMediaAccount(id) {
    return this.apiService.delete(this.baseURL2 + 'client/' + id);
  }
  saveNote(note) {
    return this.apiService.post(this.baseURL2 + 'notes', note);
  }
  resolveNote({ refCat, refNo, id, resolvedBy }) {
    return this.apiService.put(this.baseURL2 + `notes/resolve/${refCat}/${refNo}/${id}/${resolvedBy}`, {});
  }
  updatePostData(id: any, upatedata: any) {
    console.log('data and id', upatedata, id);
    let data = JSON.stringify(upatedata);
    return this.apiService.put(this.baseURL2 + `client/individual/${id}`, data);
  }
  createBankAccount(data) {
    return this.apiService.post(this.baseURL2 + 'client/bank', data);
  }
  getClientBanksList = (clientNo: string) => this.getClientBankAccounts(clientNo);
  updateClientBank(bankId, data) {
    return this.apiService.put(this.baseURL2 + '' + bankId, data);
  }
  getClientIdentification(clientNo: string) {
    return this.apiService.get<IClientKYC[]>(this.baseURL2 + 'crm/client/view/identification/' + clientNo);
  }

  getClientKyc(clientNo: string) {
    return this.apiService.get<ISearchResponse2<IClientKYC>>(
      this.baseURL2 + 'client/kyc?clientNo=' + clientNo,
    );
  }

  updateClientIdentification(data: IClientKYC) {
    return this.apiService.putWithEndorsment(this.baseURL2 + `client/kyc/${data.clientNo}`, data);
  }

  deleteClientIdentification(id) {
    return this.apiService.delete(this.baseURL2 + '' + id);
  }

  createClientIdentification(record) {
    return this.apiService.post(this.baseURL2 + '', record);
  }

  getClientEmployment(clientNo: string) {
    return this.apiService.get<IClientEmployer[]>(`${this.baseURL2}crm/client/view/employment/${clientNo}`);
  }

  getClientKYC(clientNo: string) {
    return this.apiService
      .get<IClientKYC[]>(`${this.baseURL2}crm/client/view/identification/${clientNo}`)
      .pipe(map((res) => res[0]));
  }

  getClientSocial(clientNo: string) {
    return this.apiService.get<any[]>(this.baseURL2 + 'client/social/media/' + clientNo);
  }

  createEmployment(data: Omit<IClientEmployer, 'id'>) {
    return this.apiService.postWithEndorsment(`${this.baseURL2}client/employment`, data);
  }
  updateClientEmployment(clientNo: string, data: IClientEmployer) {
    return this.apiService.putWithEndorsment<IClientEmployer>(
      `${this.baseURL2}client/employment/${clientNo}`,
      data,
    );
  }
  getAggregateLossRatio(clientNo: string) {
    return this.apiService
      .get(this.baseURL2 + 'client/cum/loss/ratio/' + clientNo)
      .pipe(map((res) => res[0]?.cumLossRatio));
  }
  downloadDocument(url: string) {
    return this.apiService.get(url, null, { options: { responseType: 'blob' } }).pipe(
      tap((res) => {
        console.log('blob response', res);
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = url.split('/').reverse()[0];
        console.log('a element before clicking', a);
        a.click();
      }),
    );
  }

  getClaimStatus() {
    return this.apiService.get(this.baseURL2 + 'status/code/description/C');
  }

  changeClientStatus(params: { clientNo: string; newStatusCode: string }) {
    params['userCode'] = environment.userName;
    return this.apiService.putWithEndorsment<{
      message?: string;
      clientNo?: string;
      newStatusCode?: string;
      userCode?: string;
      dateOfDeath?: string;
      causeOfDeath?: string;
    }>(this.baseURL2 + 'client/status' + this.apiService.getRequestParse(params), null);
  }

  changeProviderStatus(params: { providerNo; newStatus }) {
    return this.apiService.put(this.baseURL2 + 'crm/client/provider/status', params);
  }

  getClaimRequirements(claimNo) {
    return this.apiService.get(this.baseURL2 + `claim/requirements/${claimNo}`);
  }

  getProviderActions() {
    return this.apiService.get(this.baseURL2 + `codes/sub/group/PROVIDER_ACTION`);
  }
  getLanguages() {
    return this.apiService.get<ICodeTitle[]>(this.baseURL2 + 'codes/sub/category/LANGUAGE');
  }
  getTitles() {
    return this.apiService.get<ICodeTitle[]>(this.baseURL2 + 'codes/sub/category/TITLE');
  }
  getMaritalStatuses() {
    return this.apiService.getWithLocalCache<ICodeTitle[]>(
      this.baseURL2 + 'codes/sub/category/MARITAL_STATUS',
    );
  }
  getGenders() {
    return this.apiService.getWithLocalCache<ICodeTitle[]>(this.baseURL2 + 'codes/sub/category/GENDER');
  }
  updateClient(clientNo: string, data: IClientViewData) {
    return this.apiService
      .put(
        this.baseURL2 +
          (data.type == EClientType.corporate ? 'client/corporate/' : 'client/individual/') +
          clientNo,
        data,
      )
      .pipe(
        tap((r) => {
          this.userS.getUserByClientNo(clientNo).subscribe((r) => {
            if (r && (r.firstName != data.firstName || r.lastName != data.surname)) {
              r.firstName = data.firstName;
              r.lastName = data.surname;
              this.userS.updateUserInfo(r.id, r);
            }
          });
        }),
      );
  }

  getClientLossEstimateEditStatus(coverCode, claimType, policyCode, policyNo) {
    return this.apiService.get(
      `${this.baseURL2}product/covers/verify/claim/estimate?claimType=${claimType}&coverCode=${coverCode}&policyCode=${policyCode}&policyNo=${policyNo}`,
    );
  }

  getClientHealthPreExist(clientNo) {
    return this.apiService.get(`${this.baseURL2}client/health/pre/exist/${clientNo}`);
  }

  getAssured(policyNo, policyCode) {
    return this.apiService
      .get(`${this.baseURL2}policy/covers/assured/${policyNo}/${policyCode}`)
      .pipe(map((r) => (r.length ? r[0] : null)));
  }

  getAssuredName(policyNo) {
    return this.apiService
      .get(`${this.baseURL2}policy/covers/assured/name/${policyNo}`)
      .pipe(map((r) => (r.length ? r[0] : null)));
  }
  /**get source client */
  getClient(clientNo) {
    return this.apiService.get(this.baseURL2 + `client/${clientNo}`);
  }
  createProvider = (clientProviderForm: FormData) => {
    return this.apiService
      .postFile(this.baseURL + 'provider', clientProviderForm)
      .pipe(map((r) => new EndorsementResponse<IClientProviderPost>(r)));
  };
  getClientNoByProviderNo(providerNo: string) {
    return this.apiService.getText(this.baseURL + 'no/provider/' + providerNo);
  }
  /**
   * Form validator for client numbers and does not patch the form with the client data
   * @param control
   * @returns
   */
  providerNoValidatorLite = async (control: AbstractControl) => {
    // debugger
    const val: string = control?.value;
    if (!val) return { notFound: true };
    return this.uS.asyncValidation({
      uniqueKey: 'asyncValidateProviderNoClientService',
      callback: () =>
        lastValueFrom(this.getClientNoByProviderNo(val).pipe(map((r) => ({ response: !!r?.trim() })))),
    });
  };
  /**
   * Form validator for client numbers and does not patch the form with the client data
   * @param control
   * @returns
   */
  providerNoValidatorLiteCustomKey = (key: string) => async (control: AbstractControl) => {
    console.log('validate ' + key);
    const val: string = control?.value;
    if (!val) return null;
    // debugger
    return this.uS.asyncValidation({
      uniqueKey: key + 'asyncValidateProviderNoClientService',
      callback: () =>
        lastValueFrom(this.getClientNoByProviderNo(val).pipe(map((r) => ({ response: !!r?.trim() })))),
    });
  };

  getIndividualClient = (clientNo: string) => {
    return this.apiService.get<IClientIndividual>(this.baseURL + `individual/${clientNo}`);
  };

  openFindClientModal(formControl?: AbstractControl, model?: any) {
    return new Promise<IClientViewData>((res) => {
      this.uS
        .pageToModalOpener(FindClientComponent, 'Find Client', undefined, undefined, {
          height: '80%',
          width: '80%',
        })
        .subscribe((r: IClientViewData) => {
          if (r) {
            formControl?.markAsDirty();
            formControl?.patchValue(r.clientNo);
            model = r.clientNo;
          }
          res(r);
        });
    });
  }

  openFindProviderModal = (formControl?: AbstractControl, model?: any) => {
    this.uS
      .pageToModalOpener(FindProviderComponent, 'Find Provider', null, null, {
        width: '80%',
        height: '80%',
      })
      .subscribe((r) => {
        if (r) {
          console.log(r);
          formControl?.markAsDirty();
          formControl?.patchValue(r.no);
          model = r.no;
        }
      });
  };
  openCreateCorporateClientModal(formControl?: AbstractControl, model?: any) {
    this.uS
      .pageToModalOpener(CreateCorporateClientComponent, 'Create Corporate Client')
      .subscribe((r: ICorporateClient) => {
        if (r) {
          formControl?.markAsDirty();
          formControl?.patchValue(r.companyInformation?.clientNo);
          model = r.companyInformation?.clientNo;
        }
      });
  }
  openCreateIndividualClientModal(
    formControl?: AbstractControl,
    model?: any,
    config?: { modalData: { reason?: string } },
  ) {
    this.uS
      .pageToModalOpener(CreateIndividualClientComponent, 'Create Individual Client', config?.modalData)
      .subscribe((r: IClientIndividual) => {
        if (r) {
          formControl?.markAsDirty();
          formControl?.patchValue(r.personalInformation?.clientNo);
          if (model) model = r.personalInformation?.clientNo;
        }
      });
  }

  searchClientProvider = (query: ISearchProvider) => {
    return this.findProviderService.searchFull(query);
  };

  getClientDataSummary = () => {
    return this.apiService.get<{
      clientStatusSummary: {
        clientStatus: string;
        clientStatusCount: number;
      }[];
      clientCategorySummary: {
        clientCategory: string;
        clientCategoryCount: number;
      }[];
      clientTypeSummary: {
        clientType: string;
        clientTypeCount: number;
      }[];
    }>(this.baseURL + 'data/summary');
  };

  searchClaims(query: any) {
    return this.apiService.get(this.baseURL2 + 'claim/search', query).pipe(
      map((res) => res.content),
      catchError(() => of([])),
    );
  }

  getIssueAge = (query: { applicationDate: string; clientNo: string; coverCode: string }) => {
    return this.apiService.getText(this.baseURL2 + 'policy/view/issue/age', query);
  };
}

export interface IClientRelationship {
  clientNo: string;
  relationship: string;
  category?: string;
  fullName: string;
  enroleeNo?: string;
  email?: string;
  phoneNumber: string;
  id: number;
  clientStatus: string;
  policyNo?: string;
  codeTitle?: any;
  policyCode?: string;
  relClientNo: string;
  relDt: string;
  userCode?: any;
  createdBy?: string;
  rowId?: any;
}

<loader [loading]="loading">
  <form [formGroup]="individualForm" (ngSubmit)="onSubmit()">
    <app-personalinfo
      [fc]="personalF"
      [employmentInformationForm]="individualForm.controls.employmentInformation"
      [minNameLength]="minNameLength"></app-personalinfo>
    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
        {{ 'Identification' | appTranslate | async }}
      </mat-expansion-panel-header>
      <app-identification [fc]="identification" #identificationTab></app-identification>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        {{ 'Employment Information' | appTranslate | async }}
      </mat-expansion-panel-header>
      <app-employinfo [fc]="employ"></app-employinfo>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        {{ 'Payment Information' | appTranslate | async }}
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <app-create-account-form #paymentinfo [hideBlocked]="true"></app-create-account-form>
      </ng-template>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        {{ 'Health' | appTranslate | async }}
      </mat-expansion-panel-header>
      <app-client-health [form]="healthFG"></app-client-health>
    </mat-expansion-panel>
    <div class="row mt-5 justify-content-end">
      <div class="col-auto">
        <app-btn
          icon="save"
          type="secondary"
          [form]="individualForm"
          [valid]="individualForm?.valid && paymentForm?.valid"
          (mclick)="onSubmit()"
          text="Submit"></app-btn>
      </div>
      <div class="col-12">
        <form-error [form]="individualForm" />
      </div>
    </div>
  </form>
</loader>
